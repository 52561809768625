const fancyTextBox01 = [
  {
    icon: {
      class: 'line-icon-Archery-2',
    },
    title: 'STRATEGY',
    description: 'Lorem ipsum dolor consectetur adipiscing eiusmod tempor',
  },
  {
    icon: {
      class: 'line-icon-Glasses-3',
    },
    title: 'RESEARCH',
    description: 'Lorem ipsum dolor consectetur adipiscing eiusmod tempor',
  },
  {
    icon: {
      class: 'line-icon-Knight',
    },
    title: 'PLANNING',
    description: 'Lorem ipsum dolor consectetur adipiscing eiusmod tempor',
  },
  {
    icon: {
      class: 'line-icon-Coins-2',
    },
    title: 'ASSURANCE',
    description: 'Lorem ipsum dolor consectetur adipiscing eiusmod tempor',
  },
  {
    icon: {
      class: 'line-icon-Bar-Chart2',
    },
    title: 'ANALYSTS',
    description: 'Lorem ipsum dolor consectetur adipiscing eiusmod tempor',
  },
  {
    icon: {
      class: 'line-icon-Coins',
    },
    title: 'INVESTMENT',
    description: 'Lorem ipsum dolor consectetur adipiscing eiusmod tempor',
  },
];

const fancyTextBox02 = [
  {
    icon: {
      class: 'line-icon-Mail-Read text-basecolor',
    },
    title: 'How can we help you?',
    description: 'Lorem ipsum is simply dummy text of the printing industry',
    linkTitle: 'Send us an email',
    link: 'mailto:info@yourdomain.com',
  },
  {
    icon: {
      class: 'line-icon-Phone-2 text-basecolor',
    },
    title: 'Feel free to get in touch?',
    description: 'Lorem ipsum is simply dummy text of the printing industry',
    linkTitle: 'Give us a call today',
    link: '#',
  },
  {
    icon: {
      class: 'line-icon-Approved-Window text-basecolor',
    },
    title: 'Ready to request a quote?',
    description: 'Lorem ipsum is simply dummy text of the printing industry',
    linkTitle: 'Describe your project',
    link: '#',
  },
];

const fancyTextBox03 = [
  {
    icon: {
      text: '2010',
      class: 'text-gradient bg-gradient-to-r from-[#556fff] to-[#e65f9a]',
    },
    title: 'Winning design award',
    description: 'in awwwards.com',
  },
  {
    icon: {
      text: '2018',
      class: 'text-gradient bg-gradient-to-r from-[#556fff] to-[#e65f9a]',
    },
    title: 'Nominee for best jury',
    description: 'in apple developer',
  },
  {
    icon: {
      text: '2019',
      class: 'text-gradient bg-gradient-to-r from-[#556fff] to-[#e65f9a]',
    },
    title: 'Select creative designer',
    description: 'for apple designing',
  },
  {
    icon: {
      text: '2020',
      class: 'text-gradient bg-gradient-to-r from-[#556fff] to-[#e65f9a]',
    },
    title: 'Winning photography',
    description: 'award in new york',
  },
];

const fancyTextBox04 = [
  {
    icon: {
      class: 'line-icon-Gear-2 text-basecolor',
    },
    title: 'Build websites',
    subTitle: 'Easy installation theme',
    description:
      'Lorem ipsum is simply dummy text of the printing typesetting industry lorem ipsum has typesetting.',
    backgroundText: '01',
  },
  {
    icon: {
      class: 'line-icon-Transform text-basecolor',
    },
    title: 'Custom shortcodes',
    subTitle: 'Unlimited theme guide',
    description:
      'Lorem ipsum is simply dummy text of the printing typesetting industry lorem ipsum has typesetting.',
    backgroundText: '02',
  },
  {
    icon: {
      class: 'line-icon-Heart text-basecolor',
    },
    title: 'Theme advantages',
    subTitle: 'Fully optimised code',
    description:
      'Lorem ipsum is simply dummy text of the printing typesetting industry lorem ipsum has typesetting.',
    backgroundText: '03',
  },
];

const fancyTextBox05 = [
  {
    title: 'INTERIOR DESIGN IS A BUSINESS OF TRUST',
    description: 'Lorem ipsum dolor consectetur adipiscing dolor incididunt.',
    hoverImg: 'https://via.placeholder.com/1920x1000',
    link: '#',
  },
  {
    title: 'WE BELIEVE IN THE POWER OF DESIGN',
    description: 'Lorem ipsum dolor consectetur adipiscing dolor incididunt.',
    hoverImg: 'https://via.placeholder.com/1920x1000',
    link: '#',
  },
  {
    title: 'DESIGN IS A PROCESS OF DISCOVERY',
    description: 'Lorem ipsum dolor consectetur adipiscing dolor incididunt.',
    hoverImg: 'https://via.placeholder.com/1920x1000',
    link: '#',
  },
  {
    title: 'WE WANT TO BRING INTERIOR DESIGN',
    description: 'Lorem ipsum dolor consectetur adipiscing dolor incididunt.',
    hoverImg: 'https://via.placeholder.com/1920x1000',
    link: '#',
  },
];

export {
  fancyTextBox01,
  fancyTextBox02,
  fancyTextBox03,
  fancyTextBox04,
  fancyTextBox05,
};
