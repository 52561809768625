import React, { useEffect, useRef, useState } from 'react';

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { m } from 'framer-motion';

// Components
import { Header, HeaderNav, Menu } from '../Components/Header/Header';
import Buttons from '../Components/Button/Buttons';
import { fadeIn, fadeInUp } from '../Functions/GlobalAnimations';

import SocialIcons from '../Components/SocialIcon/SocialIcons';
import CustomIconWithText from '../Components/CustomIconWithText/CustomIconWithText';
import Instagram from '../Components/Instagram/Instagram';
import FancyTextBox from '../Components/FancyTextBox/FancyTextBox';
import Tab03 from '../Components/Tab/Tab03';
import InfoBannerStyle01 from '../Components/InfoBanner/InfoBannerStyle01';
import SideButtons from '../Components/SideButtons';

const SocialIconsData = [
  {
    color: '#828282',
    link: 'https://www.instagram.com/broadwayhotpot/',
    icon: 'fab fa-instagram',
  },
  // {
  //   color: '#828282',
  //   link: 'https://www.yelp.com/',
  //   icon: 'fab fa-yelp',
  // },
  {
    color: '#828282',
    link: 'https://www.xiaohongshu.com/user/profile/5c783ddb0000000017011fbf',
    alt: 'xiaohongshu',
  },
];

// broths, meat, vegetable, seafood, supreme, drinks, snacks, skewer
const NEW_BADGE = {
  title: 'NEW',
  color: '#70a967',
};
const HOT_BADGE = {
  title: 'HOT',
  color: '#d74a3a',
};
const VEGETARIAN_BADGE = {
  title: 'VEG',
  color: '#70a967',
};
const SHELLFISH_BADGE = {
  title: 'FISH',
  color: '#70a967',
};
const NUT_BADGE = {
  title: 'NUT',
  color: '#70a967',
};
const GLUTEN_FREE_BADGE = {
  title: 'GF',
  color: '#70a967',
};

const HistoryPage = (props) => {
  const [language, setLanguage] = useState('EN');

  const SwiperData = [
    {
      img: '/assets/img/hero-hotpot.png',
      title: language === 'EN' ? 'Delicious Hotpot' : '火锅盛宴',
      subtitle: language === 'EN' ? 'A Unique Experience' : '独特的体验',
      to: '/#menu',
    },
    {
      img: '/assets/img/ktv-1.jpg',
      title: language === 'EN' ? 'Karaoke Room' : 'KTV包间',
      subtitle: language === 'EN' ? "Let's Get Party Started" : '派对开始啦',
      to: '/#karaoke-room',
    },
    {
      img: '/assets/img/hero-bar.png',
      title: language === 'EN' ? 'Full Bar' : '全酒吧服务',
      subtitle: language === 'EN' ? 'Enjoy the Vibe' : '享受此时此刻',
      to: '/#menu',
    },
  ];

  const RoyaltyProgramData = [
    {
      icon: 'line-icon-Coins',
      title: language === 'EN' ? 'Points' : '积分',
      content:
        language === 'EN'
          ? 'Earn points with every dollar you spend with us'
          : '每消费一美元都可赚取积分',
    },
    {
      icon: 'line-icon-Coins-3',
      title: language === 'EN' ? 'Promotions' : '优惠福利',
      content:
        language === 'EN'
          ? 'Enjoy exclusive perks & discounts'
          : '尽享专属福利和优惠',
    },
    {
      icon: 'line-icon-Birthday-Cake',
      title: language === 'EN' ? 'Birthday Rewards' : '生日',
      content:
        language === 'EN'
          ? 'Celebrate your special day with us and enjoy your birthday rewards!'
          : '在我们这里庆祝您的生日，享受生日礼遇！',
    },
    {
      icon: 'line-icon-Information',
      title: language === 'EN' ? 'Learn More' : '了解更多',
      content:
        language === 'EN' ? 'More details coming soon' : '敬请期待更多细节',
      //content:language=== "EN"? 'Ask our staff for more details':"",
    },
  ];

  const ReservationSectionData = [
    {
      img: '/assets/img/reservation-dining.png',
      title: language === 'EN' ? 'Authentic Hotpot Experience' : '火锅体验',
      content:
        language === 'EN'
          ? 'Reserve a table for your next, or very first hotpot experience with us'
          : '预约您的下一次/首次火锅体验',
      price: 'Dining Area',
      btnName: language === 'EN' ? 'Make a Reservation' : '预约',
      btnLink: 'https://yelp.to/AzRc-Gmu79',
    },
    {
      img: '/assets/img/ktv-1.jpg',
      title: language === 'EN' ? 'Party like King & Queen' : '尽情派对',
      content:
        language === 'EN'
          ? 'Book our Karaoke room today with professional lights and devices'
          : '立即预定卡拉OK包间',
      price: 'Karaoke Room',
      btnName: language === 'EN' ? 'Make a Reservation' : '预约',
      scrollTo: 'karaokeRoomRef',
    },
    {
      img: '/assets/img/hero-bar.png',
      title: language === 'EN' ? 'Enjoy the vibe' : '尽享Fells Point氛围',
      content:
        language === 'EN'
          ? 'Not feeling a full hotpot buffet experience today? Not a problem! Grab a seat at our bar and check what we offer!'
          : '今天不想吃火锅？没问题！来我们的吧台坐坐，看看我们提供的特别选择吧！',
      price: 'Bar Area',
      btnName: language === 'EN' ? 'No reservation needed' : '无需预约',
    },
  ];

  const TabData03 = [
    {
      tabTitle: 'BROTH',
      tabicons: '',
      activities: [
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Nourishing Herbal',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Dancing Spicy',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Pork Bone',
          content: '',
          author: '',
          badges: [],
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Thai Tom Yum',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Umami Mushroom',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Buttery Spicy',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Fish Maw Chicken',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'House Tomato',
          content: '',
          author: '',
        },
      ],
    },
    {
      tabTitle: 'MEAT',
      tabicons: '',
      activities: [
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Supreme Angus Beef Brisket',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'USDA Prime Rib Slick',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'New Zealand Lamb Brisket',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Pork Belly',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Thin Sliced Chicken Breast',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Spam',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Boneless Duck Feet',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Fresh Beef Aorta',
          content: '',
          author: '',
        },
      ],
    },
    {
      tabTitle: 'VEGETARIAN',
      tabicons: '',
      activities: [
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Napa Cabbage',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Baby Bok Choy',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Fried Doughnut Sticks',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Oyster Mushroom',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Sliced Pumpkin',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Sliced Lotus Root',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Baby Bamboo Shoots',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Ramen Noodles',
          content: '',
          author: '',
        },
      ],
    },
    {
      tabTitle: 'SEAFOOD',
      tabicons: '',
      activities: [
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Shrimp',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Calamari',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Squid',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Baby Octopus',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Mussels',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Clams',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Scallop',
          content: '',
          author: '',
        },
        {
          price: '',
          img: 'https://via.placeholder.com/73x73',
          title: 'Fish Fliet',
          content: '',
          author: '',
        },
      ],
    },
    {
      tabTitle: 'DRINKS',
      tabicons: '',
      activities: [
        {
          price: '$2.50',
          img: 'https://via.placeholder.com/73x73',
          title: 'Soft Drink',
          content: '',
          author: '',
        },
        {
          price: '$8.00',
          img: 'https://via.placeholder.com/73x73',
          title: 'Coconut Mango Bliss',
          content: '',
          author: '',
        },
        {
          price: '$3.50',
          img: 'https://via.placeholder.com/73x73',
          title: 'Herbal Tea',
          content: '',
          author: '',
        },
        {
          price: '$8.00',
          img: 'https://via.placeholder.com/73x73',
          title: 'Grapefruit Flamingo',
          content: '',
          author: '',
        },
        {
          price: '$7.00',
          img: 'https://via.placeholder.com/73x73',
          title: 'Sunset Lemonade',
          content: '',
          author: '',
        },
        {
          price: '$7.00',
          img: 'https://via.placeholder.com/73x73',
          title: 'Jasmine Green Milk Boba Tea',
          content: '',
          author: '',
        },
        {
          price: '$8.00',
          img: 'https://via.placeholder.com/73x73',
          title: 'Zero Piña Coladas',
          content: '',
          author: '',
        },
        {
          price: '$3.5',
          img: 'https://via.placeholder.com/73x73',
          title: 'Plum Juice',
          content: '',
          author: '',
        },
      ],
    },
    {
      tabTitle: 'SKEWERS',
      tabicons: '',
      activities: [],
    },
    {
      tabTitle: 'COCKTAIL',
      tabicons: '',
      activities: [],
    },
    {
      tabTitle: 'SUPREME',
      tabicons: '',
      activities: [],
    },
  ];
  // actual site
  const swiperRef = useRef(null);

  useEffect(() => {
    let links = document.querySelectorAll('.section-link');

    links.forEach((item) => {
      item.addEventListener('click', () => {
        document.querySelector('.navbar-toggler:not(.collapsed)')?.click();
      });
    });
  }, []);

  const historyRef = useRef(null);
  const aboutUsRef = useRef(null);
  const menuRef = useRef(null);
  const karaokeRoomRef = useRef(null);
  const royaltyRef = useRef(null);
  const reservationRef = useRef(null);
  const findUsRef = useRef(null);

  const fancyTextBox = [
    {
      title: language === 'EN' ? 'Karaoke Room' : '卡拉OK',
      description:
        language === 'EN'
          ? 'Your private room with Karaoke device to party your way. Book your room today!'
          : '带卡拉OK设备的私人包间，让你尽情派对！立即预订你的专属包间！',
      hoverImg: '/assets/img/ktv-3.webp',
      to: karaokeRoomRef,
    },
    {
      title: language === 'EN' ? 'Hotpot' : '火锅',
      description:
        language === 'EN'
          ? 'We are first to bring a unique hotpot experience to Baltimore City. Check what we offer.'
          : '我们是首家将独特火锅体验带到巴尔的摩市的餐厅，快来看看我们为你准备的精彩体验。',
      hoverImg: '/assets/img/about-us-hotpot.png',
      to: menuRef,
    },
    {
      title: language === 'EN' ? 'Bar' : '酒吧',
      description:
        language === 'EN'
          ? 'Check our special cocktail menu'
          : '查看我们的特色鸡尾酒菜单',
      hoverImg: '/assets/img/hero-bar.png',
      to: menuRef,
    },
    {
      title: language === 'EN' ? 'Royalty Program' : '尊享会员计划',
      description:
        language === 'EN'
          ? 'Get rewards for every visit! Earn points and enjoy exclusive perks, discounts, and special offers just for you. Coming Soon!'
          : '赚取积分，享受专属福利、折扣和特别优惠。即将推出',
      hoverImg: '/assets/img/building-history.png',
      to: royaltyRef,
    },
  ];

  const handleNavClick = (to) => {
    switch (to) {
      case '/#':
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        break;
      case '/#history':
        historyRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case '/#about-us':
        aboutUsRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case '/#menu':
        menuRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case '/#karaoke-room':
        karaokeRoomRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case '/#royalty-program':
        royaltyRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case '/#reservation':
        reservationRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case '/#find-us':
        findUsRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      default:
        break;
    }
  };
  const [showHotpotHistory, setShowHotpotHistory] = useState(false);
  const [showFellsHistory, setShowFellsHistory] = useState(false);

  return (
    <>
      <SideButtons
        menuRef={menuRef}
        karaokeRoomRef={karaokeRoomRef}
        language={language}
      />
      {/* Header Start */}
      <Header
        topSpace={{ desktop: true }}
        type="reverse-scroll border-b border-[#ffffff1a] bg-white"
      >
        <HeaderNav
          theme="white"
          fluid="fluid"
          className="py-[0px] px-[35px] md:py-[15px] md:pr-[15px] sm:px-0 md:pl-0"
          containerClass="xs:pr-0"
        >
          <Col
            className="col-8 col-lg-2 me-auto ps-lg-0 xs:!p-0"
            style={{ display: 'flex', alignItems: 'center', gap: 10 }}
          >
            <Link
              aria-label="header home link"
              className="flex items-center"
              to="/"
            >
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  loading="lazy"
                  src="/assets/img/logo.png"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  loading="lazy"
                  src="/assets/img/logo.png"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  loading="lazy"
                  src="/assets/img/logo.png"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
            <div
              onClick={() =>
                setLanguage((prev) => (prev === 'EN' ? 'CN' : 'EN'))
              }
              style={{ cursor: 'pointer' }}
              ariaLabel={
                language === 'EN' ? '切换中文' : 'Translate to English'
              }
            >
              {language === 'EN' ? '中' : 'EN'}
            </div>
          </Col>
          <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px] xs:mr-[17px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-end col-auto menu-order px-lg-0">
            <Menu
              {...props}
              historyRef={historyRef}
              aboutUsRef={aboutUsRef}
              menuRef={menuRef}
              karaokeRoomRef={karaokeRoomRef}
              royaltyRef={royaltyRef}
              reservationRef={reservationRef}
              findUsRef={findUsRef}
              socialIconsData={SocialIconsData}
              language={language}
            />
          </Navbar.Collapse>
          <Col className="col-auto text-end pe-0 font-size-0 !pl-[22px] xs:hidden">
            <SocialIcons
              theme="social-icon-style-01 block text-center"
              iconColor="dark"
              size="xs"
              data={SocialIconsData}
            />
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}

      {/* Hotpot History Start */}
      <section
        ref={historyRef}
        className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] bg-no-repeat bg-cover overflow-hidden relative bg-center"
      >
        <Container>
          <Row className="items-center">
            <Col
              xl={4}
              lg={5}
              md={{ span: 6, order: 1 }}
              className="order-2 pr-0 xs:pr-[15px]"
            >
              <h2 className="heading-5 font-serif text-darkgray uppercase mb-[25px]">
                {language === 'EN' ? 'Welcome to' : '欢迎光临'}{' '}
                <span className="font-bold">
                  {language === 'EN'
                    ? 'Broadway Hotpot & Bar'
                    : '百老汇火锅酒吧'}
                </span>
              </h2>
              <blockquote className="border-l-[4px] border-basecolor text-xmd text-darkgray font-medium py-[2px] pl-[25px] pr-0 xs:mt-[30px] mt-[40px] mb-[30px] w-[85%] md:w-full">
                {language === 'EN'
                  ? 'One should not attend even the end of the world without a good hotpot'
                  : '即使世界末日，没有一顿好火锅也不值得赴约'}
              </blockquote>
              {language === 'EN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  Welcome to Broadway Hotpot & Bar, where we bring the authentic
                  flavors of Chinese hotpot to our community! At the heart of
                  Chinese culinary tradition, hotpot is more than just a meal —
                  it's a communal experience that brings friends and family
                  together. With a rich history dating back over a thousand
                  years, Chinese hotpot features a simmering pot of broth, where
                  you can cook a variety of fresh ingredients like sliced meats,
                  vegetables, seafood, and tofu to your perfection.
                </p>
              )}
              {language === 'CN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  欢迎光临百老汇火锅酒吧！我们致力于将正宗的中国火锅美味带给我们的社区。
                  火锅作为中国饮食文化的精髓，不仅是一顿丰盛的美食，更是一种让亲友团聚的温馨体验。拥有上千年历史的火锅，以滚烫的汤底为核心，您可以在其中涮煮各类新鲜食材，如肉片、蔬菜、海鲜和豆腐，随心定制您的独特美味。
                </p>
              )}
              {language === 'EN' && showHotpotHistory && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  Choose from a variety of flavorful broths, ranging from the
                  bold and spicy Sichuan style to the light and aromatic herbal
                  broths. Customize your meal with an assortment of delicious
                  dipping sauces, making each bite a unique and satisfying
                  experience. At Broadway Hotpot & Bar, we are dedicated to
                  providing you with an authentic and enjoyable hotpot dining
                  experience, filled with warmth, flavor, and the joy of sharing
                  great food.
                </p>
              )}
              {language === 'CN' && showHotpotHistory && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  在百老汇火锅酒吧，您可以选择多种风味浓郁的汤底，从麻辣鲜香的四川风味，到清淡芳香的药膳汤底，应有尽有。搭配丰富多样的蘸料，让每一口都充满独特美味，带来难忘的满足感。我们致力于为您提供正宗且愉悦的火锅体验，在温暖的氛围中感受美味与分享的乐趣。
                </p>
              )}
              <Buttons
                ariaLabel={
                  showHotpotHistory
                    ? language === 'EN'
                      ? 'show less'
                      : '收起'
                    : language === 'EN'
                    ? 'explore more'
                    : '了解更多'
                }
                className="btn-fill btn-fancy tracking-[1px] font-medium font-serif rounded-sm uppercase md:mb-[15px]"
                themeColor="#cd9452"
                color="#fff"
                size="md"
                title={
                  showHotpotHistory
                    ? language === 'EN'
                      ? 'show less'
                      : '收起'
                    : language === 'EN'
                    ? 'explore more'
                    : '了解更多'
                }
                onClick={() => {
                  setShowHotpotHistory(!showHotpotHistory);
                }}
                type="button"
              />
            </Col>
            <Col
              lg={7}
              xl={{ offset: 1 }}
              md={{ span: 6, order: 2 }}
              className="order-1 md:mb-20"
            >
              <m.img
                width="661.5px"
                height="575.2px"
                src="/assets/img/hotpot-history.png"
                loading="lazy"
                alt="cafe"
                data-no-retina=""
                {...fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Hotpot History End */}

      {/* Building History Start */}
      <section className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] bg-no-repeat bg-cover overflow-hidden relative bg-center">
        <Container>
          <Row className="items-center">
            <Col
              xl={4}
              lg={5}
              md={{ span: 6, order: 1 }}
              className="order-2 pr-0 xs:pr-[15px]"
            >
              <h2 className="heading-5 font-serif text-darkgray uppercase mb-[25px]">
                {language === 'EN'
                  ? 'Located in the Heart of Fells Point'
                  : '坐落于Fells Point的中心'}
              </h2>
              {language === 'EN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  Fells Point is one of Baltimore's most historic neighborhoods,
                  rich in maritime heritage and cultural diversity. Nestled
                  along the waterfront, the area have long been a hub of trade
                  and commerce, dating back to the 18th century.
                </p>
              )}
              {language === 'EN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  The building Broadway Hotpot & Bar located was once a German
                  immigrant shoe maker's home in 18th century. During restaurant
                  renovation, the descendants of that family visited us and
                  shared their family story. This connection to the past has
                  strengthened our dedication to preserving and celebrating
                  cultural traditions through food
                </p>
              )}
              {language === 'EN' && showFellsHistory && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  Just to the east, Canton emerged as an important part of
                  Baltimore's growing trade network. The neighborhood's name is
                  a nod to its connection to the Far East, the Chinese port of
                  Guangzhou, historically called Canton by English speakers.
                  This historic link to China, serves as an inspiration for our
                  restaurant, where we bring a taste of authentic Chinese hotpot
                  to the heart of Baltimore.
                </p>
              )}
              {language === 'CN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  费尔斯角是巴尔的摩最具历史意义的社区之一，拥有丰富的海洋遗产和多元文化。这个沿水而建的区域自18世纪以来一直是贸易和商业的中心。
                </p>
              )}
              {language === 'CN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  Broadway Hotpot &
                  Bar所在的建筑，原本是18世纪一位德国移民鞋匠的住所。在餐厅装修期间，这家族的后代还专程前来拜访，与我们分享了他们的家族故事。这段历史联系加深了我们对文化传统的敬意，也激励我们通过美食来传承和庆祝多样的文化。
                </p>
              )}
              {language === 'CN' && showFellsHistory && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  在Fells
                  Point以东不远的Canton，曾是巴尔的摩贸易网络的重要组成部分。该社区的名字呼应了其与远东的联系，源自中国的广州港，历史上被英语世界称为“Canton”。这种与中国的历史渊源为我们的餐厅带来了灵感，让我们得以将地道的中式火锅引入巴尔的摩的核心地带。
                </p>
              )}
              <Buttons
                ariaLabel={
                  showFellsHistory
                    ? language === 'EN'
                      ? 'show less'
                      : '收起'
                    : language === 'EN'
                    ? 'explore more'
                    : '了解更多'
                }
                className="btn-fill btn-fancy tracking-[1px] font-medium font-serif rounded-sm uppercase md:mb-[15px]"
                themeColor="#cd9452"
                color="#fff"
                size="md"
                title={
                  showFellsHistory
                    ? language === 'EN'
                      ? 'show less'
                      : '收起'
                    : language === 'EN'
                    ? 'explore more'
                    : '了解更多'
                }
                onClick={() => {
                  setShowFellsHistory(!showFellsHistory);
                }}
                type="button"
              />
            </Col>
            <Col
              lg={7}
              xl={{ offset: 1 }}
              md={{ span: 6, order: 2 }}
              className="order-1 md:mb-20"
            >
              <m.img
                width="661.5px"
                height="575.2px"
                src="/assets/img/building-history.png"
                loading="lazy"
                alt="cafe"
                data-no-retina=""
                {...fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Building History End */}

      {/* Specialty Start */}
      <m.section
        ref={aboutUsRef}
        className="bg-darkgray cover-background overflow-hidden"
        style={{
          backgroundImage: 'url(/assets/img/ktv-3.webp)',
        }}
        {...fadeIn}
      >
        <Container fluid className="px-0">
          <FancyTextBox
            grid="row row-cols-1 row-cols-xl-4 row-cols-sm-2 gx-0 px-0"
            themeColor=""
            className="justify-center"
            theme="fancy-text-box-05"
            data={fancyTextBox}
            animation={fadeIn}
          />
        </Container>
      </m.section>
      {/* Specialty End */}

      {/* Menu Highlight Start */}
      <m.section
        ref={menuRef}
        className="bg-[#f8f4f0] cafe-tab-style-03 py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] cover-background overflow-hidden relative"
        {...fadeIn}
      >
        <Container>
          <Row className="justify-center">
            <Col md={5} className="text-center mb-[84px] xs:mb-[70px]">
              <span className="text-xmd text-basecolor uppercase font-serif font-medium tracking-[1px] block mb-[5px]">
                {language === 'EN' ? 'Menu Highlights' : '本店特色'}
              </span>
              <h2 className="heading-5 font-serif font-bold uppercase text-darkgray mb-0">
                {language === 'EN' ? 'Discover menu' : '查看菜单'}
              </h2>
            </Col>
          </Row>
          {/* <Tab03 data={TabData03} /> */}
          <div className="text-center mt-28 lg:mt-20 md:mt-14 xs:mt-8">
            <Buttons
              ariaLabel="view hotpot menu"
              className="btn-fill mx-auto font-medium font-serif rounded-none uppercase md:mb-[15px]"
              themeColor="#cd9452"
              color="#fff"
              size="lg"
              title={
                language === 'EN'
                  ? 'Updated Menu Coming Soon'
                  : '敬请期待更新菜单'
              }
            />
            {/* <Buttons
              ariaLabel="view hotpot menu"
              className="btn-fill mx-auto font-medium font-serif rounded-none uppercase md:mb-[15px]"
              themeColor="#cd9452"
              color="#fff"
              size="lg"
              title="View Hotpot Menu"
              href="/assets/pdf/Broadway Hotpot Hotpot Menu.pdf"
              target="_blank"
            /> */}
          </div>
          <div className="text-center mt-28 lg:mt-20 md:mt-14 xs:mt-8">
            <Buttons
              ariaLabel="view bar menu"
              className="btn-fill mx-auto font-medium font-serif rounded-none uppercase md:mb-[15px]"
              themeColor="#cd9452"
              color="#fff"
              size="lg"
              title={
                language === 'EN' ? 'Bar Menu Coming Soon' : '敬请期待酒单'
              }
            />
            {/* <Buttons
              ariaLabel="view full menu"
              href="/assets/pdf/menu.pdf"
              target="_blank"
              className="btn-fill mx-auto font-medium font-serif rounded-none uppercase md:mb-[15px]"
              themeColor="#cd9452"
              color="#fff"
              size="lg"
              title="View Full Menu"
            /> */}
          </div>
        </Container>
      </m.section>
      {/* Menu Highlight End */}

      {/* KTV Start */}
      <section className="p-0" ref={karaokeRoomRef}>
        <Container fluid>
          <Row xl={4} sm={2} xs={1}>
            <m.div
              {...fadeInUp}
              className="col mt-[3.5rem] cover-background h-[650px] px-0 lg:m-0 md:h-[500px] xs:h-[450px]"
              style={{
                backgroundImage: `url(/assets/img/ktv-4.webp)`,
              }}
            >
              <div className="absolute top-0 left-0 w-full h-full opacity-70 bg-darkgray"></div>
              <div className="flex flex-col h-full p-20 text-center relative z-[1] xl:px-12 lg:px-16">
                {/* <span className="font-serif font-medium uppercase text-[#ca943d]">
                  Karaoke
                </span> */}
                <h2 className="heading-4 font-serif font-semibold text-white uppercase mb-auto mt-auto -tracking-[.5px]">
                  {language === 'EN' ? 'Karaoke' : '卡拉OK'}
                </h2>
              </div>
            </m.div>
            <m.div
              {...fadeInUp}
              className="cover-background h-[650px] px-0 md:h-[500px] xs:h-[450px]"
              style={{
                backgroundImage: `url(/assets/img/ktv-2.jpg)`,
              }}
            >
              <div className="flex flex-col h-full justify-start py-20 px-24 text-center relative z-[1] xl:px-12 lg:px-32">
                <span className="font-serif font-medium uppercase text-darkgray block mb-[15px]">
                  {language === 'EN'
                    ? 'Let us host your next party'
                    : '您的下一场派对就在这里'}
                </span>
                <h2 className="heading-4 font-serif font-semibold text-white uppercase mb-auto mt-auto -tracking-[.5px]">
                  {language === 'EN' ? 'Party Your Way' : '尽情派对，随心所欲'}
                </h2>
              </div>
            </m.div>
            <m.div
              {...fadeInUp}
              className="mt-14 cover-background h-[650px] px-0 lg:m-0 md:h-[500px] xs:h-[450px]"
              style={{
                backgroundImage: `url(/assets/img/ktv-1.jpg)`,
              }}
            >
              <div className="absolute top-0 left-0 w-full h-full opacity-70 bg-darkgray"></div>
              <div className="flex flex-col h-full p-20 text-center relative z-[1] xl:px-12 lg:px-32">
                <span className="font-serif font-medium uppercase text-[#ca943d]">
                  {language === 'EN'
                    ? 'Our light is called wild star for a reason'
                    : '来感受一下狂野之心'}
                </span>
                <h2 className="heading-4 font-serif font-semibold text-white uppercase mb-auto mt-auto -tracking-[.5px]">
                  {language === 'EN' ? 'Professional Light' : '专业灯光效果'}
                </h2>
              </div>
            </m.div>
            <m.div
              {...fadeInUp}
              className="cover-background h-[650px] px-0 md:h-[500px] xs:h-[450px]"
              style={{
                backgroundImage: `url(/assets/img/ktv-3.webp)`,
              }}
            >
              <div className="flex flex-col h-full justify-start py-20 px-24 text-center relative z-[1] xl:px-12 lg:px-32">
                <span className="font-serif font-medium uppercase text-[#ca943d]">
                  {language === 'EN'
                    ? 'Enjoy discounted price during off peak hour'
                    : '工作日时段享受优惠价格'}
                </span>
                <h2 className="heading-4 font-serif font-semibold text-white uppercase mb-auto mt-auto -tracking-[.5px]">
                  {language === 'EN' ? 'Off-peak Price' : '平日优惠'}
                </h2>
              </div>
            </m.div>
          </Row>
          <Row className="justify-center mb-4 mt-28 lg:mt-20 md:mt-14 xs:mt-8">
            <m.div className="col-md-5 text-center" {...fadeIn}>
              <span className="text-xmd text-basecolor uppercase font-serif font-medium tracking-[1px] block mb-[5px]">
                {language === 'EN' ? 'Karaoke Happy Hour' : '卡拉OK欢乐时光'}
              </span>
              <h2 className="heading-5 font-serif font-bold uppercase text-darkgray mb-0">
                {language === 'EN'
                  ? 'Half Price Mon-Thu 11am till 4pm'
                  : '每周一至周四上午11点至下午4点KTV半价优惠'}
              </h2>
            </m.div>
          </Row>
          <div
            className="row row-cols-lg-4 row-cols-sm-2 row-cols-1"
            style={{ justifyContent: 'center' }}
          >
            <div className="text-center mt-3 mb-7 lg:mt-20 md:mt-3 xs:mt-8 flex flex-col">
              <Buttons
                ariaLabel="Reserve Queen Room"
                className="btn-fill mx-auto font-medium font-serif rounded-none uppercase md:mb-[5px]"
                themeColor="#cd9452"
                color="#fff"
                size="lg"
                title="Reserve Queen Room"
                type="button"
                href="https://calendly.com/broadway-hotpot-queen-room"
                target="_blank"
              />
              <i>
                {language === 'EN'
                  ? '*Queen room holds up to 6 people'
                  : '*小包最多可供6人欢唱'}
              </i>
            </div>
            <div className="text-center mt-3 mb-7 lg:mt-20 md:mt-3 xs:mt-8 flex flex-col">
              <Buttons
                ariaLabel="Reserve King Room"
                className="btn-fill mx-auto font-medium font-serif rounded-none uppercase md:mb-[5px]"
                themeColor="#cd9452"
                color="#fff"
                size="lg"
                title="Reserve King Room"
                type="button"
                href="https://calendly.com/broadway-hotpot-king-room"
                target="_blank"
              />
              <i>
                {language === 'EN'
                  ? '*King room holds up to 20 people'
                  : '*大包最多可供20人欢唱'}
              </i>
            </div>
          </div>
          <div className="text-center flex flex-col mb-[5px]">
            <Buttons
              ariaLabel="View Karaoke Price"
              className="btn-fill mx-auto font-medium font-serif rounded-none uppercase mb-[5px]"
              themeColor="#cd9452"
              color="#fff"
              size="lg"
              title="View Karaoke Price"
              type="button"
              href="/assets/pdf/Broadway Hotpot Karaoke Price.pdf"
              target="_blank"
            />
            <i>
              {language === 'EN'
                ? '*Hotpot not included. Make hotpot reservation separately. Hotpot usually takes 60-90 mins. $10 off every KTV hour if you eat hotpot'
                : '*KTV包间不包括火锅。如有需要请另外预约火锅。请在KTV预约时间前留出60-90分钟享用火锅。如同时预定火锅及KTV，KTV房费每小时便宜$10。'}
            </i>
          </div>
        </Container>
      </section>
      {/* KTV End */}

      {/* Royalty Program Start */}
      {/* <section
        ref={royaltyRef}
        className="bg-lightgray py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]"
      >
        <Container>
          <Row className="justify-center">
            <m.div className="col-md-5 text-center mb-[4.5rem]" {...fadeIn}>
              <span className="text-xmd text-basecolor uppercase font-serif font-medium tracking-[1px] block mb-[5px]">
                {language === 'EN' ? 'amazing perks' : ''}
              </span>
              <h2 className="heading-5 font-serif font-bold uppercase text-darkgray mb-0">
                {language === 'EN' ? 'Royalty Program' : '尊享会员计划'}
              </h2>
            </m.div>
          </Row>
          <CustomIconWithText
            grid="row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-center text-center gap-y-10 landscape:md:gap-y-0"
            className=""
            theme="custom-icon-with-text01"
            data={RoyaltyProgramData}
            animation={fadeIn}
          />
        </Container>
      </section> */}
      {/* Royalty Program End */}

      {/* Reservation Start */}
      <m.section
        ref={reservationRef}
        id="classes"
        className="bg-[#faf6f3] py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]"
        {...fadeIn}
      >
        <Container>
          <InfoBannerStyle01
            grid="row row-cols-1 row-cols-lg-3 row-cols-sm-2 md:gap-y-10"
            className="justify-center"
            data={ReservationSectionData}
            animation={fadeIn}
            karaokeRoomRef={karaokeRoomRef}
          />
        </Container>
      </m.section>
      {/* Reservation End */}

      {/* Instagram Start */}
      <section className="z-[1] relative sm:pb-[50px]">
        <Container>
          <Row className="justify-center">
            <m.div className="col-md-12 col" {...fadeIn}>
              <Instagram
                carousel={true}
                carouselOption={{
                  loop: true,
                  slidesPerView: 2,
                  spaceBetween: 15,
                  autoplay: { delay: 3500, disableOnInteraction: false },
                  keyboard: { enabled: true, onlyInViewport: true },
                  breakpoints: {
                    1200: { slidesPerView: 6 },
                    992: { slidesPerView: 4 },
                    768: { slidesPerView: 3 },
                  },
                }}
                total_posts={8}
                title="#broadway hotpot"
                grid=""
              />
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Instagram End */}

      {/* Info Start */}
      <section
        ref={findUsRef}
        className="py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px]"
      >
        <Container>
          <Row lg={4} sm={2} xs={1}>
            <m.div
              {...{ ...fadeIn, transition: { delay: 0.2 } }}
              className="text-center md:mb-[40px]"
            >
              <i className="feather-map-pin text-[28px] text-[#ca943d] mb-[25px] sm:mb-[10px] block"></i>
              <div className="text-darkgray uppercase text-md font-semibold font-serif tracking-[1px] mb-[10px]">
                {language === 'EN' ? 'Find us' : '联系我们'}
              </div>
              <p className="w-[75%] lg:w-full md:w-[60%] sm:w-[75%] mx-auto">
                709 S Broadway, Baltimore, MD 21231
                <br />
                {language === 'EN' ? 'Phone' : '电话'}: 443-835-1190
              </p>
            </m.div>
            <m.div
              {...{ ...fadeIn, transition: { delay: 0.4 } }}
              className="text-center md:mb-[40px]"
            >
              <i className="feather-phone-call text-[28px] text-[#ca943d] mb-[25px] sm:mb-[10px] block"></i>
              <div className="text-darkgray uppercase text-md font-semibold font-serif tracking-[1px] mb-[10px]">
                {language === 'EN' ? 'Follow us' : '关注我们'}
              </div>
              <p className="w-[70%] lg:w-full mx-auto">
                <SocialIcons
                  theme="social-icon-style-01"
                  size="xs"
                  iconColor="dark"
                  className="justify-center text-center"
                  data={SocialIconsData}
                />
              </p>
            </m.div>
            <m.div
              {...{ ...fadeIn, transition: { delay: 0.6 } }}
              className="text-center xs:mb-[40px]"
            >
              <i className="feather-mail text-[28px] text-[#ca943d] mb-[25px] sm:mb-[10px] block"></i>
              <div className="text-darkgray uppercase text-md font-semibold font-serif tracking-[1px] mb-[10px] sm:mb-0">
                {language === 'EN' ? 'Make a reservation' : '预约'}
              </div>
              <p className="w-[70%] lg:w-full mx-auto">
                <Buttons
                  ariaLabel="Book Dining Area"
                  className="w-[190px] btn-fill mx-auto font-medium font-serif rounded-none uppercase mt-[15px] mb-[15px]"
                  themeColor="#cd9452"
                  color="#fff"
                  size="lg"
                  title={
                    language === 'EN' ? 'Book Hotpot Experience' : '预约火锅'
                  }
                  width={190}
                  href="https://yelp.to/AzRc-Gmu79"
                  target="_blank"
                />
              </p>
              <p className="w-[70%] lg:w-full mx-auto">
                <Buttons
                  ariaLabel="Book Karaoke Room"
                  className="w-[190px] btn-fill mx-auto font-medium font-serif rounded-none uppercase"
                  themeColor="#cd9452"
                  color="#fff"
                  size="lg"
                  title={
                    language === 'EN' ? 'Book a Karaoke Room' : '预约卡拉OK'
                  }
                  width={190}
                  onClick={() =>
                    karaokeRoomRef.current.scrollIntoView({
                      behavior: 'smooth',
                    })
                  }
                  type="button"
                />
              </p>
            </m.div>
            <m.div
              {...{ ...fadeIn, transition: { delay: 0.8 } }}
              className="text-center xs:mb-[40px]"
            >
              <i className="feather-globe text-[28px] text-[#ca943d] text-pink mb-[25px] sm:mb-[10px] block"></i>
              <div className="text-darkgray uppercase text-md font-semibold font-serif tracking-[1px] mb-[10px]">
                {language === 'EN' ? 'Restaurant hour' : '营业时间'}
              </div>
              <p className="w-[75%] lg:w-full md:w-[60%] sm:w-[75%] mx-auto">
                {language === 'EN' ? 'Sun - Thurs' : '周日至周四'}: 11:30 am -
                10 pm
                <br />
                <u>
                  {language === 'EN'
                    ? 'Hotpot last seating: 9 pm'
                    : '火锅最后落座时间'}
                </u>
                <br />
                <u>
                  {language === 'EN'
                    ? 'Hotpot last order: 9:30 pm'
                    : '火锅最后点单时间'}
                </u>
                <br />
                {language === 'EN' ? 'Fri - Sat' : '周五周六'}: 11:30 am - 2 am
                <br />
                <u>
                  {language === 'EN'
                    ? 'Hotpot last seating: 9:30 pm'
                    : '火锅最后落座时间'}
                </u>
                <br />
                <u>
                  {language === 'EN'
                    ? 'Hotpot last order: 10:00 pm'
                    : '火锅最后点单时间'}
                </u>
              </p>
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Info End */}

      {/* Footer Start */}
      <footer className="text-[#828282] py-[60px] border-t border-mediumgray md:pt-[80px] md:pb-[60px]">
        <Container>
          <Row className="items-center">
            <Col md={6} className="sm:mb-[15px]">
              <ul className="flex text-sm font-serif text-center justify-start md:justify-start sm:justify-center xs:text-center xs:flex-col">
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="Home Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleNavClick('/#');
                    }}
                  >
                    {language === 'EN' ? 'Home' : '主页'}
                  </a>
                </li>
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="About Us Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleNavClick('/#about-us');
                    }}
                  >
                    {language === 'EN' ? 'About Us' : '关于我们'}
                  </a>
                </li>
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="Menu Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleNavClick('/#menu');
                    }}
                  >
                    {language === 'EN' ? 'Menu' : '菜单'}
                  </a>
                </li>
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="Reservation Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleNavClick('/#reservation');
                    }}
                  >
                    {language === 'EN' ? 'Reservation' : '预约'}
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={6} className="text-center">
              <p className="text-sm uppercase flex items-center justify-center">
                © {new Date().getFullYear()} Broadway Hotpot
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
      {/* Footer End */}
    </>
  );
};

export default HistoryPage;
