import React, { useState, useEffect, useRef, memo } from 'react';

// Libraries
import { m } from 'framer-motion';
import { useLocation } from 'react-router-dom';

// Components
import useOnClickOutside from '../Functions/UseOnClickOutside';

const SideButtons = memo((props) => {
  const language = props.language;
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setShow(false));
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 100) {
        setVisible(true);
      }
    });
  }, []);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShow(false);
      }
    };

    if (show === true) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }

    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [show]);

  useEffect(
    () => {
      setVisible(false);
      if (props.animation === false) {
        setVisible(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  return (
    <m.div
      className={`${
        show
          ? 'block h-[100vh] left-0 overflow-hidden fixed top-0 w-full z-[16]'
          : 'fixed z-[16]'
      }`}
      initial={{ opacity: 0, visibility: 'hidden' }}
      animate={
        visible
          ? { opacity: 1, visibility: 'visible' }
          : { opacity: 0, visibility: 'hidden' }
      }
      transition={{ duration: 0.5 }}
    >
      <section
        className={`all-demo-btn-section z-10 lg:hidden${show ? ' show' : ''}`}
        ref={ref}
      >
        <div
          className="all-demo bottom-[230px] bg-white lg:hidden shadow-[0_0_15px_rgba(0,0,0,0.15)]"
          style={{ width: 260 }}
          onClick={() => {
            props.menuRef.current.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          <div className="theme-wrapper text-darkgray text-[14px] uppercase flex items-center">
            <i className="feather-book-open align-middle text-[21px] mr-[10px]" />
            {language === 'EN' ? 'View Menu Highlights' : '查看菜单'}
          </div>
        </div>

        <div
          className="all-demo bottom-[160px] bg-darkgray text-white lg:hidden shadow-[0_0_15px_rgba(0,0,0,0.15)]"
          style={{ width: 260 }}
          onClick={() => {
            window.open('https://yelp.to/AzRc-Gmu79', '_blank');
          }}
        >
          <i
            className={`feather-user-plus align-middle text-[21px] mr-[10px]`}
          />
          <span className="leading-[23px] uppercase text-[14px] inline-block">
            {language === 'EN' ? 'Make a Reservation Today!' : '预约火锅！'}
          </span>
        </div>

        <div
          className="all-demo bottom-[90px] bg-darkgray text-white lg:hidden shadow-[0_0_15px_rgba(0,0,0,0.15)]"
          style={{ width: 260 }}
          onClick={() => {
            props.karaokeRoomRef.current.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          <i
            className={`feather-user-plus align-middle text-[21px] mr-[10px]`}
          />
          <span className="leading-[23px] uppercase text-[14px] inline-block">
            {language === 'EN'
              ? 'Book your Karaoke Room Today!'
              : '预约卡拉OK！'}
          </span>
        </div>
      </section>
    </m.div>
  );
});

export default memo(SideButtons);
