export const InfoBannerData01 = [
  {
    img: 'https://via.placeholder.com/720x525',
    title: 'Authentic Hotpot Experience',
    content: 'Lorem ipsum dolor amet consectetur adipiscing do eiusmod tempor.',
    price: 'Dining Area',
    btnName: 'Make a Reservation',
    btnLink: '#',
  },
  {
    img: 'https://via.placeholder.com/720x525',
    title: 'Party like King & Queen',
    content: 'Lorem ipsum dolor amet consectetur adipiscing do eiusmod tempor.',
    price: 'Karaoke Room',
    btnName: 'Make a Reservation',
    btnLink: '#',
  },
  {
    img: 'https://via.placeholder.com/720x525',
    title: 'Come and enjoy the vibe',
    content: 'Lorem ipsum dolor amet consectetur adipiscing do eiusmod tempor.',
    price: 'Bar Area',
    btnName: 'No reservation needed',
  },
];

export const InfoBannerData03 = [
  {
    img: 'https://via.placeholder.com/281x217',
    title: 'Sausage rolls',
    content: 'Lorem ipsum is simply dummy text of the printing industry',
    price: 'JUST $19.00',
  },
  {
    img: 'https://via.placeholder.com/281x217',
    title: 'Cheese pancake',
    content: 'Lorem ipsum is simply dummy text of the printing industry',
    price: 'JUST $21.00',
  },
  {
    img: 'https://via.placeholder.com/281x217',
    title: 'Beef sandwich',
    content: 'Lorem ipsum is simply dummy text of the printing industry',
    price: 'JUST $16.00',
  },
];

export const InfoBannerData05 = [
  {
    img: 'https://via.placeholder.com/525x431',
    packageprice: 'From $350',
    days: '08 Days',
    title: 'Golden triangle of incredible india',
    reviews: '20 Reviews',
    link: '#',
    rating: 5,
  },
  {
    img: 'https://via.placeholder.com/525x431',
    packageprice: 'From $250',
    days: '10 Days',
    title: 'Maldives super resorts with flights',
    reviews: '18 Reviews',
    link: '#',
    rating: 5,
  },
  {
    img: 'https://via.placeholder.com/525x431',
    packageprice: 'From $700',
    days: '07 Days',
    title: 'Dubai parks & resorts special packages',
    reviews: '05 Reviews',
    link: '#',
    rating: 5,
  },
  {
    img: 'https://via.placeholder.com/525x431',
    packageprice: 'From $350',
    days: '05 Days',
    title: 'Majestic india life and great wildlife',
    reviews: '30 Reviews',
    link: '#',
    rating: 5,
  },
  {
    img: 'https://via.placeholder.com/525x431',
    packageprice: 'From $250',
    days: '10 Days',
    title: 'Maldives super resorts with flights',
    reviews: '18 Reviews',
    link: '#',
    rating: 5,
  },
];
